import { ShopSummaryContent } from '../../../stories/ShopSummaryContent';
import {
  ApiBillingInterval,
  ApiGetShopItemsResponse,
} from '../../../models/openapi/openapiTypes';
import { Button } from '../../../stories/Button';
import * as shopSelectors from '../selectors';
import * as commonSelectors from '../../common/selectors';
import * as shopActions from '../actions';
import { RootState } from 'typesafe-actions';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ShopDownloadContractButton from './ShopDownloadContractButton';
import { useState } from 'react';

const mapStateToProps = (state: RootState) => ({
  cart: shopSelectors.currentCart(state.shop),
  calculatedCart: shopSelectors.currentCalculatedCart(state.shop),
  user: commonSelectors.user(state.common),
  shopUser: shopSelectors.shopUser(state.shop),
  contractPreview: shopSelectors.contractPreview(state.shop),
});

const dispatchProps = {
  submitCart: shopActions.submitCartAsync.request,
  changeBillingInterval: shopActions.currentCartChangeBillingInterval,
};

interface ComponentProps {
  shopItemEntries: ApiGetShopItemsResponse;
  isAtCheckout: boolean;
  selectedShippingAddress: string | undefined;
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const ShopSummary: React.FC<Props> = ({
  selectedShippingAddress,
  user,
  shopUser,
  calculatedCart,
  shopItemEntries,
  cart,
  isAtCheckout,
  changeBillingInterval,
  submitCart,
}) => {
  const history = useHistory();
  const calculatedCartData = calculatedCart?.data;
  const loading = calculatedCart?.loading ?? true;
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  return (
    <ShopSummaryContent
      isAtCheckout={isAtCheckout}
      loading={loading || !calculatedCartData}
      shopItemEntries={shopItemEntries}
      calcdItems={calculatedCartData?.items}
      total={calculatedCartData?.total}
      billingInterval={cart.billingInterval}
      setBillingInterval={event =>
        changeBillingInterval(
          event.target.value as unknown as ApiBillingInterval
        )
      }
      ContractButton={<ShopDownloadContractButton />}
      setupFee={1}
      shippingFee={1}
      termsAccepted={termsAccepted}
      setTermsAccepted={setTermsAccepted}
      CheckoutButton={
        user != null || shopUser != null ? (
          <Button
            disabled={selectedShippingAddress === undefined || !termsAccepted}
            label="Zahlungspflichtig bestellen"
            variant="success"
            fullWidth
            onClick={() => {
              submitCart({
                shippingAddressId: selectedShippingAddress!,
                cart: cart,
                calculatedCart: calculatedCartData!,
              });
              history.push(`/shop/exit`);
            }}
          />
        ) : (
          <Button
            label="Anmelden um Bestellung abzuschließen"
            fullWidth
            // redirect to the authorized shop page
            onClick={() => {
              history.push(`/shop`);
            }}
          />
        )
      }
    />
  );
};

export default connect(mapStateToProps, dispatchProps)(ShopSummary);
