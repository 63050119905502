import React, { useState } from 'react';
import { UserRole } from '../features/common/models/user-role';
import useWindowSize from '../hooks/useWindowSize';
import styles from './Header.module.scss';
import { HeaderLink } from './HeaderLink';
import {
  Close,
  Expert,
  Icon,
  Logo,
  Logout,
  Menu,
  PropaneTank,
  ShoppingCart,
  Speed,
  Tile,
} from './icons';

interface Props {
  userRole?: UserRole;
  onSignOut?: () => void;
  Notifications?: any;
  publicPage?: boolean;
}

export const Header: React.FC<Props> = ({
  userRole = UserRole.Default,
  Notifications,
  onSignOut,
  publicPage,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  let { width } = useWindowSize();

  if (width < 768) {
    return (
      <>
        <div className={styles['gaas-header']}>
          <Icon className={styles['gaas-header--logo']} />
          <div className={styles['gaas-header--menu']}>
            {Notifications}
            <HeaderLink
              title={mobileMenuOpen ? 'Menü schliessen' : 'Menü öffnen'}
              type="button"
              Icon={mobileMenuOpen ? Close : Menu}
              onClick={
                mobileMenuOpen
                  ? () => setMobileMenuOpen(false)
                  : () => setMobileMenuOpen(true)
              }
            />
          </div>
        </div>

        {!publicPage && mobileMenuOpen ? (
          <div className={styles['gaas-header--drawer']}>
            <HeaderLink
              url="inventory"
              label="Bestand"
              Icon={PropaneTank}
              mobile
              onClick={() => setMobileMenuOpen(false)}
            />
            <HeaderLink
              url="groups"
              label="Gruppen"
              Icon={Tile}
              mobile
              onClick={() => setMobileMenuOpen(false)}
            />
            <HeaderLink
              url="digidrumi"
              label="Manometer"
              Icon={Speed}
              mobile
              onClick={() => setMobileMenuOpen(false)}
            />
            <HeaderLink
              url="shop"
              label="Shop"
              Icon={ShoppingCart}
              mobile
              onClick={() => setMobileMenuOpen(false)}
            />
            {userRole === UserRole.Admin && (
              <HeaderLink
                url="admin"
                label="Administration"
                Icon={Expert}
                mobile
                onClick={() => setMobileMenuOpen(false)}
              />
            )}
            <HeaderLink
              type="button"
              Icon={Logout}
              label="Logout"
              onClick={onSignOut}
              mobile
            />
          </div>
        ) : (
          mobileMenuOpen && (
            <div className={styles['gaas-header--drawer']}>
              <HeaderLink
                url="public/shop"
                label="Shop"
                Icon={ShoppingCart}
                mobile
                onClick={() => setMobileMenuOpen(false)}
              />
            </div>
          )
        )}
      </>
    );
  }

  return (
    <div className={styles['gaas-header']}>
      {width < 1024 ? (
        <Icon className={styles['gaas-header--logo']} />
      ) : (
        <Logo className={styles['gaas-header--logo']} />
      )}

      {!publicPage ? (
        <div className={styles['gaas-header--menu']}>
          <HeaderLink url="inventory" label="Bestand" Icon={PropaneTank} />
          <HeaderLink url="groups" label="Gruppen" Icon={Tile} />
          <HeaderLink url="digidrumi" label="Manometer" Icon={Speed} />
          <HeaderLink url="shop" label="Shop" Icon={ShoppingCart} />
          {userRole === UserRole.Admin && (
            <HeaderLink url="admin" label="Administration" Icon={Expert} />
          )}
          {Notifications}
          <HeaderLink
            type="button"
            Icon={Logout}
            label="Logout"
            onClick={onSignOut}
          />
        </div>
      ) : (
        <div className={styles['gaas-header--menu']}>
          <HeaderLink url="public/shop" label="Shop" Icon={ShoppingCart} />
        </div>
      )}
    </div>
  );
};
