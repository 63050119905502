import React from 'react';
import styles from './ShopOrderConfirmationPage.module.scss';
import { Button } from '../../../stories/Button';

interface Props {}

export const ShopOrderConfirmationPage: React.FC<Props> = () => {
  return (
    <div className={styles['gaas-shopConfirm--background']}>
      <div className={styles['gaas-shopConfirm']}>
        <h1>Vielen Dank für Ihre Bestellung</h1>
        <p>
          Ihre Bestellung ist bei uns eingegangen und Ihr Vertrag wird in Kürze
          eingerichtet. ? Sie können Ihre Vertragsdaten jederzeit in Ihrem
          persönlichen Bereich unter Digitale Services - GaaS® einsehen. ?
        </p>
        <p>
          Sollten Sie in der Zwischenzeit Fragen zu Ihrer Bestellung haben,
          melden Sie sich gerne über{' '}
          <a href="mailto:support@mail.gaas.westfalen.com">
            support@mail.gaas.westfalen.com
          </a>
          .
        </p>

        <Button label="Zurück zum Bestand?" />
      </div>
    </div>
  );
};
