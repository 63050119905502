import { components } from './openapiSchema';

export type ApiCustomerManagedGroup =
  components['schemas']['CustomerManagedGroup'];

export type ApiCustomerManagedGroupCreateRequest =
  components['schemas']['CreateGroupRequest'];

export type ApiCustomerManagedGroupDeleteRequest =
  components['schemas']['DeleteGroupRequest'];

export type ApiChangeContainerGroupAssignmentRequest =
  components['schemas']['ChangeContainerGroupAssignmentRequest'];

export type ApiContainerGroupAssignment =
  components['schemas']['ContainerGroupAssignment'];

export type ApiGetValveAssignmentResponse =
  components['schemas']['GetValveAssignmentResponse'];

export type ApiAnyNotification = components['schemas']['Notification'];
export type ApiUserPressureThresholdNotification =
  components['schemas']['UserPressureThresholdNotification'];
export type ApiUserSuspiciousValuesNotification =
  components['schemas']['UserSuspiciousValuesNotification'];
export type ApiUserValveOfflineNotification =
  components['schemas']['UserValveOfflineNotification'];
export type ApiUserInventoryThresholdNotification =
  components['schemas']['UserInventoryThresholdNotification'];

export function isApiUserPressureThresholdNotification(
  notification: ApiAnyNotification
): notification is ApiUserPressureThresholdNotification {
  return notification.type === 'pressureThreshold';
}

export function isApiUserSuspiciousValuesNotification(
  notification: ApiAnyNotification
): notification is ApiUserSuspiciousValuesNotification {
  return notification.type === 'suspiciousValues';
}

export function isApiUserValveOfflineNotification(
  notification: ApiAnyNotification
): notification is ApiUserValveOfflineNotification {
  return notification.type === 'valveOffline';
}

export function isApiUserInventoryThresholdNotification(
  notification: ApiAnyNotification
): notification is ApiUserInventoryThresholdNotification {
  return notification.type === 'inventoryThreshold';
}

export type ApiNotificationType =
  components['schemas']['PlatformNotificationType'];

export type ApiCustomer = components['schemas']['Customer'];

export type ApiMaintenanceNote = components['schemas']['MaintenanceNote'];
export type ApiMaintenanceNoteRequest =
  components['schemas']['MaintenanceNoteRequest'];
export type ApiContainerNote = components['schemas']['ContainerNoteResponse'];

export type ApiGatewayStateView = components['schemas']['GatewayStateView'];

export type ApiOrder = components['schemas']['Order'];

export type ApiInventoryThresholdView =
  components['schemas']['InventoryThresholdView'];

export type ApiInventoryThresholdPassedEventView =
  components['schemas']['InventoryThresholdPassedEventView'];

export type ApiInventory = components['schemas']['Inventory'];

export type ApiStockContainer = components['schemas']['StockContainer'];

export type ApiContainerManagementType =
  components['schemas']['ContainerManagementType'];
export type ApiContainerFillLevel = components['schemas']['ContainerFillLevel'];

export type ApiManualContainerState =
  components['schemas']['ManualContainerState'];
export type ApiAutomaticContainerState =
  components['schemas']['AutomaticContainerState'];
export type ApiContainerState =
  | ApiManualContainerState
  | ApiAutomaticContainerState;

export type ApiManualContainerStateView =
  components['schemas']['ManualContainerStateView'];
export type ApiAutomaticContainerStateView =
  components['schemas']['AutomaticContainerStateView'];
export type ApiContainerStateView =
  | ApiManualContainerStateView
  | ApiAutomaticContainerStateView;

export type ApiConsumptionHistory = components['schemas']['ConsumptionHistory'];
export type ApiConsumptionHistoryItem =
  components['schemas']['ConsumptionHistoryItem'];

export type ApiGaasUserRole = components['schemas']['GaasUserRole'];
export enum GaasUserRole {
  Free = 'role-free',
  Basic = 'role-basic',
  Premium = 'role-premium',
  Admin = 'role-admin',
  Empty = 'role-empty',
}

export type ApiGaasSubscription = components['schemas']['GaasSubscription'];
export enum GaasSubscription {
  Free = 'subscription-free',
  Transparency = 'subscription-transparency',
  Empty = 'subscription-empty',
}

export type ApiPermissionInformation = {
  userPermissions: {
    [key: string]: {
      actions: {
        [key: string]: components['schemas']['PermissionState'];
      };
      role: components['schemas']['GaasUserRole'];
      subscription: components['schemas']['GaasSubscription'];
    };
  };
  templates: {
    roles: {
      [key in GaasUserRole]: {
        actions: components['schemas']['GaasAction'][];
        weight: number;
      };
    };
    subscriptions: {
      [key in GaasSubscription]: {
        actions: components['schemas']['GaasAction'][];
        weight: number;
      };
    };
  };
};
export type ApiPermissionState = components['schemas']['PermissionState'];
export type ApiGaasAction = components['schemas']['GaasAction'];

export type ApiCalculateCartRequest =
  components['schemas']['CalculateCartRequest'];
export type ApiCalculateCartResponse =
  components['schemas']['CalculateCartResponse'];
export type ApiCalculatedCartItem = components['schemas']['CalculatedCartItem'];
export type ApiCartItem = components['schemas']['CartItem'];

export type ApiBillingInterval = components['schemas']['BillingInterval'];
export type ApiRenderContractResponse =
  components['schemas']['RenderContractResponse'];

export type ApiGetShopAddressesResponse =
  components['schemas']['GetShopAddressesResponse'];
export type ApiAddress = components['schemas']['ShopAddress'];
export type ApiSubmitCartRequest = components['schemas']['SubmitCartRequest'];
export type ApiGetShopItemsResponse =
  components['schemas']['GetShopItemsResponse'];

export type ApiGetShopAccessibleCustomers =
  components['schemas']['ShopAccessibleCustomerNumbers'];
