import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Skeleton } from './Skeleton';
import { Devider } from './Devider';
import styles from './ShopSummaryContent.module.scss';
import { Radio } from './Radio';
import { components } from '../models/openapi/openapiSchema';
import { TextButton } from './TextButton';
import { Checkbox } from './Checkbox';

//TODO @Sebastian is this bad? Did not know how to import the type
interface Item {
  itemId: string;
  quantity: number;
  price: string;
  sum: string;
}

interface Props {
  isAtCheckout: boolean;
  loading: boolean;
  shopItemEntries?: {
    itemId: string;
    price: string;
    name: string;
    category: components['schemas']['ShopItemCategory'];
  }[];
  calcdItems?: Item[];
  total?: string;
  billingInterval: 'yearly' | 'monthly';
  setBillingInterval: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setupFee?: number;
  shippingFee?: number;
  ContractButton: ReactNode;
  termsAccepted: boolean;
  setTermsAccepted: Dispatch<SetStateAction<boolean>>;
  CheckoutButton: ReactNode;
}

export const ShopSummaryContent: React.FC<Props> = ({
  isAtCheckout,
  loading,
  shopItemEntries,
  calcdItems,
  total,
  billingInterval,
  setBillingInterval,
  setupFee,
  shippingFee,
  ContractButton,
  termsAccepted,
  setTermsAccepted,
  CheckoutButton,
}) => {
  const [height, setHeight] = useState<number | undefined>();
  const Ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (Ref && Ref.current) {
      if (!loading) {
        Ref.current.style.height = '';
        setHeight(Ref.current.clientHeight);
      }
      if (loading) {
        Ref.current.style.height = height + 'px';
      }
    }
  }, [loading, height]);

  let subItems: Item[] = [];
  let userItems: Item[] = [];
  let meterItems: Item[] = [];
  let gatewayItems: Item[] = [];

  if (calcdItems) {
    calcdItems.forEach(item => {
      if (item.quantity) {
        let foundShopItem = shopItemEntries?.find(
          it => it.itemId === item.itemId
        );

        switch (foundShopItem?.category) {
          case 'subscription':
            subItems.push(item);
            break;
          case 'user':
            userItems.push(item);
            break;
          case 'meter':
            meterItems.push(item);
            break;
          case 'gateway':
            gatewayItems.push(item);
            break;
        }
      }
    });
  }

  const Content = () => {
    if (loading) {
      return (
        <>
          {isAtCheckout ? <h1>Vertragsdaten</h1> : <h2>Vertragsdaten</h2>}
          <Skeleton height={24} width={150} mt={16} mb={16} />
          <Skeleton height={24} />
          <br />
          <Skeleton height={24} width={150} mb={16} />
          <Skeleton height={24} mb={16} />
          <Skeleton height={24} />
          <Devider />
          <Skeleton height={24} width={150} mb={16} />
          <Skeleton height={24} />
        </>
      );
    } else {
      const Items = (arr: Item[]) =>
        arr.map((item, index) => (
          <div
            className={styles['gaas-shopSummary--item']}
            key={`${item.itemId}|${index}-checkout-line`}
          >
            <span>
              {item.quantity}{' '}
              {shopItemEntries?.find(it => it.itemId === item.itemId)?.name ??
                'Unbekannt'}
            </span>
            <span>{item.sum} EUR</span>
          </div>
        ));

      return (
        <>
          {isAtCheckout ? <h1>Vertragsdaten</h1> : <h2>Vertragsdaten</h2>}
          <h4>Service Level:</h4>
          {Items(subItems)}
          {userItems.length ? <h4>Nutzer Lizenzen:</h4> : <></>}
          {Items(userItems)}
          {meterItems.length || gatewayItems.length ? (
            <h4 className={styles['gaas-shopSummary--specialTitle']}>
              Gewählte Mietgeräte <span>(12 Mon. Mindestlaufzeit):</span>
            </h4>
          ) : (
            <></>
          )}
          {Items(meterItems)}
          {Items(gatewayItems)}
          <Devider />
          <h3>Gesamtsumme (zzgl. MwSt.)</h3>
          <div className={styles['gaas-shopSummary--item']}>
            <Radio
              name="billingInterval"
              onChange={setBillingInterval}
              options={[
                {
                  label: 'Jährlich',
                  value: 'yearly',
                  checked: billingInterval === 'yearly',
                },
                {
                  label: 'Monatlich',
                  value: 'monthly',
                  checked: billingInterval === 'monthly',
                },
              ]}
            />
            <span className={styles['gaas-shopSummary--total']}>
              {total} EUR
            </span>
          </div>

          <div className={styles['gaas-shopSummary--checkout']}>
            {(!!setupFee || !!shippingFee) && (
              <div>
                <Devider />

                <div>
                  <h4 style={{ marginTop: 0 }}>Zzgl. einmaliger Kosten:</h4>
                  {setupFee && (
                    <div className={styles['gaas-shopSummary--item']}>
                      <span>Einrichtungsgebühr</span>
                      <span>{setupFee} EUR</span>
                    </div>
                  )}
                  {shippingFee && (
                    <div className={styles['gaas-shopSummary--item']}>
                      <span>Versand</span>
                      <span>{shippingFee} EUR</span>
                    </div>
                  )}
                </div>
              </div>
            )}

            {isAtCheckout && ContractButton}

            {isAtCheckout && (
              <Checkbox
                label={
                  <div>
                    Hiermit akzeptiere ich den Vetrag und die{' '}
                    <TextButton label="Widerrufsbelehrung" inline />. Die{' '}
                    <a
                      href="https://westfalen.com/de/de/datenschutz/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TextButton label="Datenschutzerklärung" inline />
                    </a>{' '}
                    und Informationen nach Art. 13 DSGVO habe ich zur Kenntnis
                    genommen.
                  </div>
                }
                checked={termsAccepted}
                onClick={() => setTermsAccepted(!termsAccepted)}
              />
            )}
            {isAtCheckout && CheckoutButton}
          </div>
        </>
      );
    }
  };

  return (
    <div className={styles['gaas-shopSummary']} ref={Ref}>
      {Content()}
    </div>
  );
};
